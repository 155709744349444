<template>
  <div class="container profile">
    <div class="payment-header">
      <!-- <div class="title">플러스 멤버십 출시 기념<br>70%할인 (기간 한정)</div> -->
      <!-- <div class="text">프로모션 기간을 놓치지 마세요.<br>지금 가입하고 남들보다<br>더 많은 기회와 노출을 보장 받으세요.</div> -->
      <img
        :src="$lib.imgUrl('img/membership/new-membership-payment-header.jpg')"
        alt="플러스 멤버십"
        width="100%"
      />
    </div>

    <div class="ui-border-line" style="height: 10px ui-mb-6"></div>

    <div class="profile-info">
      <div class="ui-border-line ui-mb-6" style="height: 10px"></div>

      <h4>결제 금액</h4>
      <div class="ui-border-line ui-mt-3 ui-mb-1"></div>
      <div class="info-block">
        <ul>
          <li>플러스 멤버십</li>
          <li class="float-right" style="text-decoration: line-through;">{{$lib.addComma(membershipPrice)}}원</li>
        </ul>
        <div class="ui-border-line ui-mt-1 ui-mb-1"></div>
      </div>
      <!--  -->
      <div class="info-block" v-if="specialDiscountRate">
        <ul>
          <li>특별 할인</li>
          <li class="float-right">{{specialDiscountRate}}%</li>
        </ul>
        <div class="ui-border-line ui-mt-1 ui-mb-1"></div>
      </div>
      <!--  -->
      <div class="info-block">
        <ul>
          <li class="color-lavender ui-weight-6">최종 결제 금액</li>
          <li class="color-lavender ui-weight-6 float-right">{{$lib.addComma(finalPrice)}}원</li>
        </ul>
        <div class="ui-border-line ui-mt-1"></div>
      </div>

    </div>

    <div class="ui-border-line" style="height: 10px"></div>

    <div class="profile-teamDesc">
      <h4>멤버십 이용 및 해지 안내</h4>
      <p class="ui-p-indent">- 멤버십 혜택은 결제 즉시 제공됩니다.</p>
      <p class="ui-p-indent">- 혜택은 30일간 제공되며 30일 후 자동 종료됩니다.</p>
      <p class="ui-p-indent">- 종료 시 자동 연장 및 자동 결제 되지 않습니다.</p>
      <p class="ui-p-indent">- 운영 정책 위반(이용정지)으로 멤버십 이용이 불가한 경우 책임은 본인에게 있습니다.</p>
      <p class="ui-p-indent">- 본 상품은 선불 서비스로 취소 및 환불이 불가합니다.<br>(일할계산 되지 않음)</p>
    </div>
    <div class="ui-border-line" style="height: 10px"></div>

    <left-check-box
      class="ui-mt-2 ui-mb-2"
      text="위 구매조건 확인 및 결제 진행 동의"
      :value="agreeCheck"
      @click="result => {
        agreeCheck = result.checked
        result.update()
      }"
      />
    <div class="ui-border-line" style="height: 10px"></div>

    <form ref="iniForm" method="post" :action="iniBill.actionUrl" :accept-charset="iniBill.acceptCharset" hidden>
      <input type="hidden" name="inipaymobile_type" :value="iniBill.form.inipaymobile_type" />
      <input type="hidden" name="paymethod" :value="iniBill.form.paymethod" />
      <input type="hidden" name="P_MID" :value="iniBill.form.P_MID" />
      <input type="hidden" name="P_OID" :value="iniBill.form.P_OID" />
      <input type="hidden" name="P_AMT" :value="iniBill.form.P_AMT" />
      <input type="hidden" name="P_UNAME" :value="iniBill.form.P_UNAME" />
      <input type="hidden" name="P_MNAME" :value="iniBill.form.P_MNAME" />
      <input type="hidden" name="P_NOTI" :value="iniBill.form.P_NOTI" />
      <input type="hidden" name="P_GOODS" :value="iniBill.form.P_GOODS" />
      <input type="hidden" name="P_MOBILE" :value="iniBill.form.P_MOBILE" />
      <input type="hidden" name="P_EMAIL" :value="iniBill.form.P_EMAIL" />
      <input type="hidden" name="P_RESERVED" :value="iniBill.form.P_RESERVED" />
      <input type="hidden" name="P_NEXT_URL" :value="iniBill.form.P_NEXT_URL" />
      <input type="hidden" name="P_QUOTABASE" :value="iniBill.form.P_QUOTABASE" />
    </form>

    <footer-box
      :submit-text="'결제하기 >'"
      :submit-cb="() => iniBillRun()"
      :submit-disabled="!agreeCheck"
    >
    </footer-box>

  </div>
</template>

<script>
import Icon from '@/components/common/Icon'
import FooterBox from '@/components/common/FooterBox'
import LeftCheckBox from '@/components/common/LeftCheckBox'

export default {
  name: 'NewMembershipPayment',
  components: {
    Icon,
    FooterBox,
    LeftCheckBox,
  },
  data() {
    return {
      agreeCheck: false,
      iniBill: {
        actionUrl: '',
        acceptCharset: '',
        form: {
          // 기본값
          inipaymobile_type: '',
          // 결제방법
          paymethod: '',
          // 이니시스 에서 발급받은 mid 값
          P_MID: '',
          // 상점에서 생성한 주문번호
          P_OID: '',
          // 상품결제요청 가격
          P_AMT: '',
          // 결제요청 user 이름
          P_UNAME: '',
          // 상점이름
          P_MNAME: '',
          // artistidx|useridx
          P_NOTI: '',
          // 상품이름
          P_GOODS: '',
          // 결제요청 user 전화번호
          P_MOBILE: '',
          // user 메일
          P_EMAIL: '',
          // 결제옵션 파라미터
          P_RESERVED: '',
          // 인증 결과 수신 url (클라이동용)
          P_NEXT_URL: '',
          // 할부 기간
          P_QUOTABASE: '',
        },
      },
    }
  },
  computed: {
    userData() {
      return this.$store.state.user.userData
    },
    artistData() {
      return this.$store.state.user.artistData
    },
    isNewMembershipUser() {
      return this.$store.getters.isNewMembershipUser
    },
    billData() {
      return this.$store.state.billData || {}
    },
    membershipPrice() {
      return 49000
    },
    specialDiscountRate() {
      return 20
    },
    finalPrice() {
      return 39000
      // return Math.floor(this.membershipPrice * (100 - this.specialDiscountRate) / 100)
    },
  },
  mounted() {
    const p_msg = this.$route.query.p_msg

    if (localStorage.getItem('TryPayment')) {
      localStorage.removeItem('TryPayment')

      if (p_msg) {
        this.$store.commit('setModal', {
          show: true,
          body: p_msg,
          useCancel: false,
        })
        return
      }

      this.$historyStack.set([
        '/home',
        '/artist/my-profile',
      ])
      this.$historyStack.get()
      return
    }

    if (this.userData.category === 'client') {
      this.$router.back()
      return
    }

    if (this.isNewMembershipUser) {
      this.$router.back()
      return
    }
  },
  methods: {
    iniBillRun() {
      const order_form = this.$refs.iniForm
      const API_URL = this.$lib.API_URL
      const paymethod = 'wcard'

      const userData = this.userData
      const useridx = userData.useridx

      const artistData = this.artistData
      const artistidx = artistData.artistidx

      this.iniBill.actionUrl = `https://mobile.inicis.com/smart/${paymethod}/`
      this.iniBill.acceptCharset = 'EUC-KR'

      this.iniBill.form.inipaymobile_type = 'web'
      this.iniBill.form.paymethod = paymethod

      this.iniBill.form.P_MID = this.billData.iniMid
      this.iniBill.form.P_OID = 'heybeagle_' + useridx + '_' + new Date().getTime()
      this.iniBill.form.P_AMT = this.finalPrice
      this.iniBill.form.P_UNAME = userData.userName
      this.iniBill.form.P_MNAME = 'HeyBeagle'
      this.iniBill.form.P_NOTI = `${artistidx}|${useridx}`
      this.iniBill.form.P_GOODS = 'member_30'
      this.iniBill.form.P_MOBILE = userData.phone
      this.iniBill.form.P_EMAIL = userData.email
      this.iniBill.form.P_RESERVED = 'twotrs_isp=Y&block_isp=Y&twotrs_isp_noti=N&ismart_use_sign=Y&vbank_receipt=N&bank_receipt=N&apprun_check=Y&useescrow=N&app_scheme=iamporthb://'
      this.iniBill.form.P_NEXT_URL = `${API_URL}/web/payment8`
      this.iniBill.form.P_QUOTABASE = '01:03:06:09:12'

      this.$nextTick().then(() => {
        // const form = new FormData(order_form)
        // const json = JSON.stringify(Object.fromEntries(form))
        // console.log(json)

        this.$router.replace({
          query: {
            p_msg: '결제가 취소되었습니다.',
          },
        }).finally(() => {
          localStorage.setItem('TryPayment', Date.now().toString())
          order_form.submit()
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

.payment-header {
  background-color: $color-deepLavender;
  color: #fff;
  text-align: center;
  margin: 0 -3rem;
  overflow: hidden;
  .title {
    margin: 6rem 0 3rem 0;
    font-size: 3.2rem;
    font-weight: 600;
  }
  .text {
    margin: 3rem 0 6rem 0;
    font-size: 2rem;
    line-height: 1.5;
  }
  .image {
    div {
      margin: 0 auto;
    }
  }
}
.artist-image {
  display: block;
  float: left;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  margin-top: -1rem;
  margin-bottom: -0.6rem;
  margin-right: 1rem;
}
#page > .container.profile {
  .profile-info .info-block ul li {
    &:first-child {
      width: auto;
      color: #464646;
    }
    &:nth-child(2) {
      font-weight: 500;
    }
  }
}
</style>
